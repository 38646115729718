import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get, toLower } from "lodash";
import { setPlatformUID } from "../../../../../Actions";
import {
  getIsFetching,
  getIsFetchingSettings,
  getSettings,
} from "../../../../../Selectors";
import { getSettings as getSupplierSettings } from "../../../../../views/supplier/SupplierSelectors";
import createNotification from "../../../../../modules/Notification";
import {
  AccountButton,
  AccountInput,
  AccountSubtitle,
  AccountTitle,
  Form,
} from "../../../../../views/account/components/AccountComponents";
import styled from "styled-components";
import { theme } from "../../../../../modules/Theme";
import { ButtonNew } from "../../../../../components";
import { Modal } from "../../../../../components/Modal";
import { FiCheck } from "react-icons/fi";
import Cookies from "js-cookie";
import { isEmpty } from "lodash";
import { randomString, shopifyInstallationURL } from "../../../../../modules/Format";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  supplierSettings: getSupplierSettings(state),
  isFetching: getIsFetching(state),
  isFetchingSettings: getIsFetchingSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators({ setPlatformUID }, dispatch),
});

const PlatformWrapper = styled.div`
  padding: 30px;
  border: 1px solid ${theme.colors.lightBorder};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  max-width: 100%;
  margin-bottom: 20px;
`;

const PlatformLogo = styled.img`
  height: 60px;
  margin-bottom: 20px;

  @media (max-width: 950px) {
    height: 45px;
  }
`;

class Platform extends Component {
  state = { showModal: false, uid: "", errors: {} };

  fixPotentialErrors = () => {
    let uid = this.state.uid;

    uid = uid.replace("www.", "");
    uid = uid.replace("https://", "");
    uid = uid.replace("http://", "");
    uid = uid.replace(".com/", ".com");
    return uid;
  };

  getResource = () => {
    let storeId = get(this.props, ["settings", "store", "id"]);
    let resourceType = "store";
    let resourceId = storeId;

    let supplierId = get(this.props, ["supplierSettings", "id"]);
    if (supplierId) {
      resourceId = supplierId;
      resourceType = "supplier";
    }
    return { type: resourceType, id: resourceId };
  };

  isShopifyValid = () => {
    const errors = {};

    const uid = this.fixPotentialErrors();
    if (!uid.includes(".myshopify.com")) {
      errors.shopify =
        "Please enter a valid Shopify URL ending in .myshopify.com";
    }
    this.setState({ errors });
    return isEmpty(errors);
  };

  setShopifyUID = () => {
    if (!this.isShopifyValid()) return;
    const resource = this.getResource();
    const uid = this.fixPotentialErrors();
    // Set cookie for validation after user agrees to scope
    const randString = randomString(20);
    Cookies.set("nonce", randString, { expires: 1 });
    const redirectURL = shopifyInstallationURL(uid, randString);
    // Create the platform in DC core API
    this.props.setPlatformUID(uid, null, resource, redirectURL);
  };

  isShoplazaValid = () => {
    const errors = {};

    const uid = this.fixPotentialErrors();
    if (!uid.includes(".myshoplaza.com")) {
      errors.shoplaza =
        "Please enter a valid Shoplaza URL ending in .myshoplaza.com";
    }
    this.setState({ errors });
    return isEmpty(errors);
  };

  setShoplazaUID = () => {
    if (!this.isShoplazaValid()) return; // abort

    const resource = this.getResource();
    const uid = this.fixPotentialErrors();
    // async
    this.props.setPlatformUID(uid, "shoplaza", resource).then(() => {
      this.setState({ showModal: null });
    });
  };

  connectPlatform = (showModal) => {
    if (showModal) {
      this.setState({ showModal: true });
    } else {
      createNotification({
        title: "Platform Connected",
        type: "info",
        message:
          "You can only have one platform connected at a time. To switch platforms, contact support@dropcommerce.com",
      });
    }
  };

  render() {
    const { name, logo, platform } = this.props;
    const isConnected = get(platform, "is_connected") || false;
    const lowerCaseName = name ? name.toLowerCase() : "none";
    const lowerCasePlatform = toLower(get(platform, "name")) || "";
    const connectedPlatform =
      isConnected && lowerCasePlatform === lowerCaseName;
    const disableButton = isConnected && lowerCasePlatform !== lowerCaseName;

    return (
      <PlatformWrapper>
        <PlatformLogo alt={name} src={logo} />

        <ButtonNew
          text={
            connectedPlatform
              ? "Connected - View Details"
              : `Connect ${name} Store`
          }
          icon={isConnected && <FiCheck size="18" color="white" />}
          disabled={disableButton}
          isFetching={this.props.isFetchingSettings}
          onClick={() => {
            this.connectPlatform(!disableButton);
          }}
        />

        {this.state.showModal &&
          (lowerCaseName === "shoplazza" || lowerCaseName === "shoplaza") && (
            <Modal
              maxWidth="600px"
              hide={() => this.setState({ showModal: null })}
            >
              {isConnected ? (
                <Form>
                  <AccountTitle>Shoplaza URL</AccountTitle>
                  <AccountSubtitle>{platform.uid}</AccountSubtitle>
                </Form>
              ) : (
                <Form>
                  <AccountTitle>Connect Shoplaza</AccountTitle>
                  <AccountSubtitle>
                    Enter your original Shoplaza subdomain. It should look like{" "}
                    <strong>yourstore.myshoplaza.com</strong>.
                  </AccountSubtitle>
                  <AccountInput
                    onChange={(e) => this.setState({ uid: e.target.value })}
                    placeholder="yourstore.myshoplaza.com"
                    extraStyle="margin-bottom: 5px;"
                    error={this.state.errors.shoplaza}
                  />
                  <AccountButton
                    text="Connect Shoplaza"
                    isFetching={this.props.isFetching > 0}
                    onClick={this.setShoplazaUID}
                  />
                </Form>
              )}
            </Modal>
          )}

        {this.state.showModal && lowerCaseName === "woocommerce" && (
          <Modal
            maxWidth="600px"
            hide={() => this.setState({ showModal: null })}
          >
            <Form>
              <AccountTitle>Coming Soon</AccountTitle>
              <AccountSubtitle>
                We're just putting the finishing touches on our WooCommerce
                integration. Send us an email at support@dropcommerce.com if you
                want to be one of the first to use our WooCommerce integration.
              </AccountSubtitle>
            </Form>
          </Modal>
        )}

        {this.state.showModal && lowerCaseName === "bigcommerce" && (
          <Modal
            maxWidth="600px"
            hide={() => this.setState({ showModal: null })}
          >
            {isConnected ? (
              <Form>
                <AccountTitle>BigCommerce ID</AccountTitle>
                <AccountSubtitle>{platform.uid}</AccountSubtitle>
              </Form>
            ) : (
              <Form>
                <AccountTitle>BigCommerce</AccountTitle>
                <AccountSubtitle>
                  Click the button below to install the DropCommerce app to your
                  BigCommerce store.
                </AccountSubtitle>
                <AccountButton
                  text="Connect BigCommerce"
                  isFetching={this.props.isFetching > 0}
                  onClick={() =>
                    window.open(
                      "https://www.bigcommerce.com/apps/dropcommerce-us-dropshipping/"
                    )
                  }
                />
              </Form>
            )}
          </Modal>
        )}

        {this.state.showModal && lowerCaseName === "wix" && (
          <Modal
            maxWidth="600px"
            hide={() => this.setState({ showModal: null })}
          >
            {isConnected ? (
              <Form>
                <AccountTitle>Wix ID</AccountTitle>
                <AccountSubtitle>{platform.uid}</AccountSubtitle>
              </Form>
            ) : (
              <Form>
                <AccountTitle>Wix</AccountTitle>
                <AccountSubtitle>
                  You will be re-directed to your Wix dashboard. Follow the
                  steps and see you in a bit!
                </AccountSubtitle>
                <AccountButton
                  text="Connect Wix Site"
                  isFetching={this.props.isFetching > 0}
                  onClick={() => {
                    Cookies.set("wixID", this.props.settings.email, {
                      expires: 1,
                    });
                    window.open(
                      "https://www.wix.com/app-market/dropcommerce-us-dropshipping"
                    );
                  }}
                />
              </Form>
            )}
          </Modal>
        )}

        {this.state.showModal && lowerCaseName === "manual" && (
          <Modal
            maxWidth="600px"
            hide={() => this.setState({ showModal: null })}
          >
            {isConnected ? (
              <Form>
                <AccountTitle>Manual Integration ID</AccountTitle>
                <AccountSubtitle>{this.props.settings.email}</AccountSubtitle>
              </Form>
            ) : (
              <Form>
                <AccountTitle>Manual Integration</AccountTitle>
                <AccountSubtitle>
                  Click the button below to set up your store manually
                </AccountSubtitle>
                <AccountButton
                  text="Connect Manual Store"
                  isFetching={this.props.isFetching > 0}
                  onClick={() => {
                    this.props.setPlatformUID("", "manual").then(() => {
                      this.setState({ showModal: null });
                    });
                  }}
                />
              </Form>
            )}
          </Modal>
        )}

        {this.state.showModal && lowerCaseName === "shopify" && (
          <Modal
            maxWidth="600px"
            hide={() => this.setState({ showModal: null })}
          >
            {isConnected ? (
              <Form>
                <AccountTitle>Shopify URL</AccountTitle>
                <AccountSubtitle>{platform.uid}</AccountSubtitle>
              </Form>
            ) : (
              <Form>
                <AccountTitle>Connect Shopify</AccountTitle>
                <AccountSubtitle>
                  Enter your original Shopify subdomain. It should look like{" "}
                  <strong>yourstore.myshopify.com</strong>.
                </AccountSubtitle>
                <AccountInput
                  onChange={(text) => this.setState({ uid: text.target.value })}
                  placeholder="yourstore.myshopify.com"
                  extraStyle="margin-bottom: 5px;"
                  error={this.state.errors.shopify}
                />
                <AccountButton
                  text="Connect Shopify"
                  isFetching={this.props.isFetching > 0}
                  onClick={this.setShopifyUID}
                />
              </Form>
            )}
          </Modal>
        )}
      </PlatformWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Platform)
);
