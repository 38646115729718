import React from "react";
import { errorNotification } from "../modules/Notification";
import { axiosInstance } from "../services/api";

/**
 * Reusable logic to add/remove a supplier to/from your favorite list.
 */
export function useFavoriteSupplier(favorite, storeId, supplierId) {
  const [isFavorite, setIsFavorite] = React.useState(favorite);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleFavoriteSupplier = () => {
    setIsLoading(true);
    axiosInstance
      .post("api/favourite-suppliers/", {
        store: storeId,
        supplier: supplierId,
      })
      .then((responseData) => {
        setIsFavorite(true);
      })
      .catch((error) => {
        errorNotification("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUnfavoriteSupplier = () => {
    setIsLoading(true);
    axiosInstance
      .delete(`api/favourite-suppliers/${supplierId}`)
      .then((responseData) => {
        setIsFavorite(false);
      })
      .catch((error) => {
        errorNotification("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isFavorite,
    isLoading,
    handleFavoriteSupplier,
    handleUnfavoriteSupplier,
  };
}
