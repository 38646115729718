import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { login, shopifyAuth, wixAuth, shoplazzaAuth } from "../../Actions";
import { getError, getIsFetching, getSubdomainData } from "../../Selectors";
import Cookies from "js-cookie";
import {
  getAppName,
  getDefaultStoreRoute,
  getUrlParameter,
} from "../../modules/Format";
import Account, {
  AccountButton,
  AccountInput,
  AccountLink,
  Form,
  LoginOptions,
} from "./components/AccountComponents";
import createNotification from "../../modules/Notification";
import { get, isEmpty } from "lodash";
import { trackEvent } from "../../modules/EventTracking";
import { validEmail, validPassword } from "../../validation";

const mapStateToProps = (state) => ({
  error: getError(state),
  isFetching: getIsFetching(state),
  subdomainData: getSubdomainData(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      login,
      shopifyAuth,
      wixAuth,
      shoplazzaAuth,
    },
    dispatch
  ),
});

const defaultProps = {};

function getTitle(appName) {
  switch (appName) {
    case "PREBUILT":
      return "Prebuilt Stores";
    case "PORTAL":
      return "Dropship Portal Login";
    default:
      return "Sign In";
  }
}

class Login extends Component {
  state = {
    errors: {},
    email: "",
    password: "",
  };

  onChange = (e) => {
    if (e.key === "Enter") {
      this.loginUser();
    }
  };

  isValid = () => {
    const errors = {};

    const { email, password } = this.state;

    if (!validPassword(password)) {
      errors.password = "Please enter a longer, more secure password";
    }

    if (!validEmail(email)) {
      errors.email = "Please enter a valid email address";
    }

    this.setState((previousState) => ({ ...previousState, errors: errors }));

    return isEmpty(errors);
  };

  loginUser = () => {
    if (this.isValid()) {
      this.props.login(this.state.email, this.state.password);
    }
  };

  validateShopifyUrlParams = () => {
    const loc = this.props.location;
    const shop = getUrlParameter("shop", loc);
    if (shop.includes("shoplaza")) return false;

    const code = getUrlParameter("code", loc);
    const hmac = getUrlParameter("hmac", loc);
    const state = getUrlParameter("state", loc);
    const timestamp = getUrlParameter("timestamp", loc);
    const app_name = getAppName();
    let source = Cookies.get("dc_referral_source");

    if (code && hmac && shop && state && timestamp) {
      if (Cookies.get("nonce") === state) {
        // STORE AUTH
        this.props.shopifyAuth({
          code,
          shop,
          state,
          timestamp,
          hmac,
          created_at: moment(),
          source,
          app_name,
        });
      } else {
        // SHOW UNEXPECTED ERROR CONNECTING TO SHOPIFY
        createNotification({
          type: "danger",
          message:
            "Unexpected error connecting to Shopify. Contact support@dropcommerce.com",
        });
      }
      return true;
    }
    return false;
  };

  checkMagicLink = () => {
    const magic = getUrlParameter("magic", this.props.location);
    const type = getUrlParameter("type", this.props.location);
    const tab = getUrlParameter("tab", this.props.location);
    const source = getUrlParameter("ref", this.props.location);

    window.tap("detect");

    if (source) {
      Cookies.set("dc_referral_source", source, { expires: 30 });
    }

    if (magic) {
      Cookies.set("accessToken", magic, { expires: 1 });

      if (type) {
        switch (type) {
          case "supplier_forgot_password":
            this.props.history.push("/supplier/settings/login_details");
            break;
          case "store_forgot_password":
            this.props.history.push("/settings/password");
            break;
          case "store_profile_settings":
            this.props.history.push("/settings/profile");
            break;
          case "store_new_order":
            this.props.history.push("/order/new");
            break;
          case "wholecommerce":
            this.props.history.push("/dcfulfillment");
            break;
          case "supplier_inbox":
            this.props.history.push("/supplier/inbox");
            break;
          case "inbox":
            this.props.history.push("/inbox");
            break;
          case "issues":
            this.props.history.push("/imported/store?status=issues");
            break;
          case "setup":
            this.props.history.push("/setup");
            break;
          case "imports":
            this.props.history.push("/imports");
            break;
          case "accounts":
            this.props.history.push("/accounts");
            break;
          case "settings":
            this.props.history.push("/settings");
            break;
          case "platforms":
            this.props.history.push("/settings/platforms");
            break;
          case "prebuilt":
            this.props.history.push("/prebuilt");
            break;
          case "changes":
            this.props.history.push("/notifications");
            break;
          case "store":
            this.props.history.push(getDefaultStoreRoute);
            break;
          case "store_notifications":
            this.props.history.push("/settings/notifications");
            break;
          case "plans":
            this.props.history.push("/settings/plans");
            break;
          case "promotions":
            this.props.history.push("/settings/plans?tab=promotions");
            break;
          case "order":
            this.props.history.push(`/order/${tab}`);
            break;
          case "analytics":
            this.props.history.push("/supplier/analytics");
            break;
          case "vendors":
            this.props.history.push("/supplier/vendors/private");
            break;
          case "unpaid_orders":
            this.props.history.push("/orders");
            break;
          case "unshipped_orders":
            this.props.history.push("/supplier/orders");
            break;
          case "supplier":
            this.props.history.push("/supplier/products");
            break;
          case "suppliers":
            this.props.history.push("/supplier/products");
            break;
          case "supplier_settings":
            if (tab) {
              this.props.history.push(`/supplier/settings/${tab}`);
            } else {
              this.props.history.push("/supplier/settings/shipping");
            }
            break;
          case "supplier_refunds":
            this.props.history.push(`/supplier/supplier-refunds`);
            break;
          case "store_refunds":
            this.props.history.push("/settings/refunds");
            break;
          default:
            // Patrick, did this to fix a lint warning
            if (type.includes("inbox?")) {
              this.props.history.push(`/${type}`);
              break;
            }
            this.props.history.push("/dashboard");
            break;
        }
      }
    } else {
      if (Cookies.get("accessToken")) {
        this.props.history.push("/accounts");
      }
    }
  };

  componentDidMount = () => {
    trackEvent("Login");

    // UNAUTHORIZED
    if (getUrlParameter("type", this.props.location) === "unauthorized") {
      createNotification({
        type: "danger",
        message: "Your login session has expired. Please login again.",
      });
    }

    const shoplazza = this.validateShoplazzaUrlParams();
    const shopify = this.validateShopifyUrlParams();
    const wix = this.validateWixUrlParams();

    if (!shopify && !wix && !shoplazza) {
      this.checkMagicLink();
    }
  };

  validateWixUrlParams = () => {
    const platform = getUrlParameter("platform", this.props.location);
    const code = getUrlParameter("code", this.props.location);
    const instanceId = getUrlParameter("instanceId", this.props.location);
    if (platform === "wix" && code && instanceId) {
      this.setState({ isFetching: true }, () => {
        this.props.wixAuth(code, instanceId, this.props.history);
      });
      return true;
    }
    return false;
  };

  validateShoplazzaUrlParams = () => {
    const code = getUrlParameter("code", this.props.location);
    const hmac = getUrlParameter("hmac", this.props.location);
    const state = getUrlParameter("state", this.props.location);
    const shop = getUrlParameter("shop", this.props.location);

    if (shop && shop.includes("shoplaza") && code && hmac && state) {
      this.setState({ isFetching: true }, () => {
        this.props.shoplazzaAuth(code, hmac, state, shop);
      });

      return true;
    }
    return false;
  };

  render() {
    const appName = getAppName();
    const title = getTitle(appName);
    const fetchingSubdomainData = get(this.props, [
      "subdomainData",
      "isFetching",
    ]);


    return (
      <Account
        title={title}
        isSupplierPortal={appName === "PORTAL"}
        isFetching={
          this.state.isFetching ||
          this.props.isFetching > 0 ||
          fetchingSubdomainData
        }
        sideImage={appName === "PORTAL"}
      >
        <AccountLink
          margin="15px 0 0 0"
          normalText="Don't have an account?"
          linkText="Sign up"
          onClick={() => this.props.history.push("/signup")}
        />
        <Form>
          <AccountInput
            onChange={(text) => this.setState({ email: text.target.value })}
            onKeyPress={(e) => this.onChange(e)}
            placeholder="Your email"
            value={this.state.email}
            error={this.state.errors.email}
          />
          <AccountInput
            onChange={(text) => this.setState({ password: text.target.value })}
            onKeyPress={(e) => this.onChange(e)}
            placeholder="Your password"
            value={this.state.password}
            error={this.state.errors.password}
            type="password"
          />
          <AccountButton text="Sign in" onClick={this.loginUser} />
          <AccountLink
            margin="20px 0 0 0"
            linkText="Forgot your password?"
            onClick={() => this.props.history.push("/forgot")}
          />
        </Form>
        <LoginOptions appName={appName} />
      </Account>
    );
  }
}

Login.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
