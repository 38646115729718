import axios from "axios";
import Cookies from "js-cookie";
import { api } from "../modules/Config";

export const axiosInstance = axios.create({
  baseURL: api,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("accessToken");

    const headers = {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    };

    // Do something before request is sent
    return { ...config, headers };
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // console.log({ response });

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
