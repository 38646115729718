import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../modules/Theme";
import Account, {
  AccountTitle,
  AccountSubtitle,
  AccountButton,
} from "./components/AccountComponents";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchAccountType } from "../../Actions";
import { getSettings, getIsFetching } from "../../Selectors";
import { get } from "lodash";
import createNotification from "../../modules/Notification";
import { getDefaultStoreRoute } from "../../modules/Format";
import { clearSettings } from "../../actionCreators";
import { clearSupplierSettings } from "../supplier/actionCreators";

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isFetching: getIsFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchAccountType,
      clearSettings,
      clearSupplierSettings,
    },
    dispatch
  ),
});

const AccountType = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  margin: 10px;
  box-sizing: border-box;
`;

const AccountImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AccountImage = styled.img`
  width: 70px;
  height: 70px;
  padding: 10px 10px;
`;

const AccountTypeRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

class ChooseAccount extends Component {
  componentDidMount() {
    this.props.fetchAccountType().then((response) => {
      if (response.account_type === "affiliate") {
        this.props.history.push("/affiliate/dashboard");
      } else if (response.account_type === "supplier") {
        this.props.history.push("/supplier/products");
      } else if (response.account_type === "store") {
        this.props.history.push(getDefaultStoreRoute);
      }
    });
  }

  selectDropshipper = (hasAccount) => {
    if (hasAccount) {
      this.props.dispatch(clearSupplierSettings());
      this.props.history.push(getDefaultStoreRoute);
    } else {
      createNotification({
        title: "Error",
        type: "default",
        message:
          "To sign up as a dropshipper, please contact support@dropcommerce.com",
      });
    }
  };

  selectSupplier = (hasAccount) => {
    if (hasAccount) {
      this.props.dispatch(clearSettings());
      this.props.history.push("/supplier/products");
    } else {
      window.open("https://app.dropcommerce.com/apply/supplier");
    }
  };

  render() {
    const accountType = get(this.props, ["settings", "account_type"]);
    const dropshipper = ["accounts", "store"].includes(accountType);
    const supplier = ["accounts", "supplier"].includes(accountType);

    return (
      <Account
        isFetching={this.props.isFetching > 0}
        title="Select App"
        subtitle="Hi! We've found multiple accounts in our system. Which app would you like to launch?"
      >
        <AccountTypeRow>
          <AccountType>
            <AccountImageWrapper>
              <AccountImage src="https://dropcommerce-images.s3.ca-central-1.amazonaws.com/logos/logo.webp" />
            </AccountImageWrapper>
            <AccountTitle style={{ fontSize: "30px" }}>
              DropCommerce
            </AccountTitle>
            <AccountSubtitle>
              For online store owners looking to add products from high quality
              suppliers
            </AccountSubtitle>
            <AccountButton
              text={dropshipper ? "Dropship on DropCommerce" : "Sign Up"}
              onClick={() => this.selectDropshipper(dropshipper)}
            />
          </AccountType>

          <AccountType>
            <AccountImageWrapper>
              <AccountImage src="https://dropcommerce-images.s3.ca-central-1.amazonaws.com/logos/logo-suppliers.webp" />
            </AccountImageWrapper>
            <AccountTitle style={{ fontSize: "30px" }}>
              Sell on DropCommerce
            </AccountTitle>
            <AccountSubtitle>
              For product makers who want to sell their products in many online
              stores
            </AccountSubtitle>
            <AccountButton
              text={supplier ? "Sell On DropCommerce" : "Sign Up"}
              onClick={() => this.selectSupplier(supplier)}
            />
          </AccountType>
        </AccountTypeRow>
      </Account>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChooseAccount)
);
