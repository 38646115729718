import Cookies from "js-cookie";
import moment from "moment";
import { api } from "../../modules/Config";
import { checkStatus } from "../../utils/index";
import { getUrlSubdomain } from "../../modules/Format";
import { trackEvent } from "../../modules/EventTracking";
import createNotification, {
  errorNotification,
  successNotification,
} from "../../modules/Notification";
import { get } from "lodash";

const type = "products";

export const ADD_TO_IMPORT_LIST_REQ = `${type}/ADD_TO_IMPORT_LIST_REQ`;
export const ADD_TO_IMPORT_LIST_RES = `${type}/ADD_TO_IMPORT_LIST_RES`;
export const ADD_TO_IMPORT_LIST_ERR = `${type}/ADD_TO_IMPORT_LIST_ERR`;
export const UPDATE_IMPORT_LIST_COUNT = `base/UPDATE_IMPORT_LIST_COUNT`;
export const UPDATE_IMPORTED_TO_SHOPIFY_COUNT = `base/UPDATE_IMPORTED_TO_SHOPIFY_COUNT`;
export const UPDATE_IMPORT_LIST_ITEM_REQ = `${type}/UPDATE_IMPORT_LIST_ITEM_REQ`;
export const UPDATE_IMPORT_LIST_ITEM_RES = `${type}/UPDATE_IMPORT_LIST_ITEM_RES`;
export const UPDATE_IMPORT_LIST_ITEM_ERR = `${type}/UPDATE_IMPORT_LIST_ITEM_ERR`;
export const TOGGLE_SELECT_IMPORT_LIST_ITEM_REQ = `${type}/TOGGLE_SELECT_IMPORT_LIST_ITEM_REQ`;
export const TOGGLE_SELECT_IMPORT_LIST_REQ = `${type}/TOGGLE_SELECT_IMPORT_LIST_REQ`;
export const SET_IMPORT_LIST_ITEM_SAVED_RES = `${type}/SET_IMPORT_LIST_ITEM_SAVED_RES`;
export const IMPORT_PRODUCT_REQ = `${type}/IMPORT_PRODUCT_REQ`;
export const IMPORT_PRODUCT_RES = `${type}/IMPORT_PRODUCT_RES`;
export const IMPORT_PRODUCT_ERR = `${type}/IMPORT_PRODUCT_ERR`;
export const FETCH_PRODUCTS_REQ = `${type}/FETCH_PRODUCTS_REQ`;
export const FETCH_PRODUCTS_RES = `${type}/FETCH_PRODUCTS_RES`;
export const FETCH_PRODUCTS_ERR = `${type}/FETCH_PRODUCTS_ERR`;
export const REQUEST_APPROVAL_REQ = `${type}/REQUEST_APPROVAL_REQ`;
export const REQUEST_APPROVAL_RES = `${type}/REQUEST_APPROVAL_RES`;
export const REQUEST_APPROVAL_ERR = `${type}/REQUEST_APPROVAL_ERR`;
export const FETCH_IMPORTED_PRODUCTS_REQ = `${type}/FETCH_IMPORTED_PRODUCTS_REQ`;
export const FETCH_IMPORTED_PRODUCTS_RES = `${type}/FETCH_IMPORTED_PRODUCTS_RES`;
export const FETCH_IMPORTED_PRODUCTS_ERR = `${type}/FETCH_IMPORTED_PRODUCTS_ERR`;
export const DELETE_PRODUCT_RES = `${type}/DELETE_PRODUCT_RES`;
export const DELETE_PRODUCT_REQ = `${type}/DELETE_PRODUCT_REQ`;
export const DELETE_PRODUCT_ERR = `${type}/DELETE_PRODUCT_ERR`;
export const UPDATE_PRODUCT_FROM_LS = `${type}/UPDATE_PRODUCT_FROM_LS`;
export const UPDATE_PRODUCTS_FROM_LS = `${type}/UPDATE_PRODUCTS_FROM_LS`;
export const SET_LAST_PAGINATION_STATE = `${type}/SET_LAST_PAGINATION_STATE`;
export const GENERIC_REQ = `${type}/GENERIC_REQ`;
export const GENERIC_RES = `${type}/GENERIC_RES`;
export const GENERIC_ERR = `${type}/GENERIC_ERR`;
export const FETCH_SUPPLIER_REQ = `${type}/FETCH_SUPPLIER_REQ`;
export const FETCH_SUPPLIER_RES = `${type}/FETCH_SUPPLIER_RES`;
export const FETCH_SUPPLIER_ERR = `${type}/FETCH_SUPPLIER_ERR`;
export const FETCH_SUPPLIERS_REQ = `${type}/FETCH_SUPPLIERS_REQ`;
export const FETCH_SUPPLIERS_RES = `${type}/FETCH_SUPPLIERS_RES`;
export const FETCH_SUPPLIERS_ERR = `${type}/FETCH_SUPPLIERS_ERR`;
export const REMOVE_FROM_IMPORT_LIST_RES = `${type}/REMOVE_FROM_IMPORT_LIST_RES`;
export const REMOVE_FROM_IMPORT_LIST_REQ = `${type}/REMOVE_FROM_IMPORT_LIST_REQ`;
export const REMOVE_FROM_IMPORT_LIST_ERR = `${type}/REMOVE_FROM_IMPORT_LIST_ERR`;
export const FETCH_IMPORT_LIST_RES = `${type}/FETCH_IMPORT_LIST_RES`;
export const FETCH_IMPORT_LIST_REQ = `${type}/FETCH_IMPORT_LIST_REQ`;
export const FETCH_IMPORT_LIST_ERR = `${type}/FETCH_IMPORT_LIST_ERR`;
export const FETCH_IMPORT_LIST_PRODUCT_REQ = `${type}/FETCH_IMPORT_LIST_PRODUCT_REQ`;
export const FETCH_IMPORT_LIST_PRODUCT_RES = `${type}/FETCH_IMPORT_LIST_PRODUCT_RES`;
export const FETCH_IMPORT_LIST_PRODUCT_ERR = `${type}/FETCH_IMPORT_LIST_PRODUCT_ERR`;
export const DELETE_SUPPLIER_PRODUCTS_REQ = `${type}/DELETE_SUPPLIER_PRODUCTS_REQ`;
export const DELETE_SUPPLIER_PRODUCTS_RES = `${type}/DELETE_SUPPLIER_PRODUCTS_RES`;
export const DELETE_SUPPLIER_PRODUCTS_ERR = `${type}/DELETE_SUPPLIER_PRODUCTS_ERR`;
export const MARK_FEATURED_REQ = `${type}/MARK_FEATURED_REQ`;
export const MARK_FEATURED_RES = `${type}/MARK_FEATURED_RES`;
export const MARK_FEATURED_ERR = `${type}/MARK_FEATURED_ERR`;
export const ADD_VARIANT_TO_CART_REQ = `${type}/ADD_VARIANT_TO_CART_REQ`;
export const ADD_VARIANT_TO_CART_RES = `${type}/ADD_VARIANT_TO_CART_RES`;
export const ADD_VARIANT_TO_CART_ERR = `${type}/ADD_VARIANT_TO_CART_ERR`;
export const ADD_PRODUCT_TO_CART_REQ = `${type}/ADD_PRODUCT_TO_CART_REQ`;
export const ADD_PRODUCT_TO_CART_RES = `${type}/ADD_PRODUCT_TO_CART_RES`;
export const ADD_PRODUCT_TO_CART_ERR = `${type}/ADD_PRODUCT_TO_CART_ERR`;
export const FETCH_ALL_STORE_PRODUCTS_RES = `${type}/FETCH_ALL_STORE_PRODUCTS_RES`;
export const GENERATE_PRODUCT_DESCRIPTION_REQ = `${type}/GENERATE_PRODUCT_DESCRIPTION_REQ`;
export const GENERATE_PRODUCT_DESCRIPTION_RES = `${type}/GENERATE_PRODUCT_DESCRIPTION_RES`;
export const GENERATE_PRODUCT_DESCRIPTION_ERR = `${type}/GENERATE_PRODUCT_DESCRIPTION_ERR`;
export const FETCH_NOTIFICATIONS_RES = `${type}/FETCH_NOTIFICATIONS_RES`;
export const FETCH_UNSYNCED_PRODUCTS_RES = `${type}/FETCH_UNSYNCED_PRODUCTS_RES`;
export const SELECT_VARIANT_OPTION = `${type}/SELECT_VARIANT_OPTION`;
export const FIX_UNSYNCED_PRODUCT_REQ = `${type}/FIX_UNSYNCED_PRODUCT_REQ`;
export const FIX_UNSYNCED_PRODUCT_RES = `${type}/FIX_UNSYNCED_PRODUCT_RES`;
export const FIX_UNSYNCED_PRODUCT_ERR = `${type}/FIX_UNSYNCED_PRODUCT_ERR`;
export const CREATE_REVIEW_RES = `${type}/CREATE_REVIEW_RES`;
export const CREATE_REVIEW_REQ = `${type}/CREATE_REVIEW_REQ`;
export const CREATE_REVIEW_ERR = `${type}/CREATE_REVIEW_ERR`;
export const FETCH_DASHBOARD_DATA_REQ = `${type}/FETCH_DASHBOARD_DATA_REQ`;
export const FETCH_DASHBOARD_DATA_RES = `${type}/FETCH_DASHBOARD_DATA_RES`;
export const FETCH_DASHBOARD_DATA_ERR = `${type}/FETCH_DASHBOARD_DATA_ERR`;
export const CREATE_REFUND_RES = `${type}/CREATE_REFUND_RES`;
export const CREATE_REFUND_REQ = `${type}/CREATE_REFUND_REQ`;
export const CREATE_REFUND_ERR = `${type}/CREATE_REFUND_ERR`;

export const clearErrors = () => (dispatch) => {
  dispatch({
    type: "products/CLEAR_ERRORS",
  });
};

export const fetchDashboardData = () => (dispatch) => {
  dispatch({
    type: FETCH_DASHBOARD_DATA_REQ,
  });
  trackEvent("View Dashboard");
  fetch(`${api}/dashboard/`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: FETCH_DASHBOARD_DATA_RES,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error: "Error fetching samples" },
      });
    });
};

export const selectVariantOption = (variantId) => (dispatch) => {
  dispatch({
    type: SELECT_VARIANT_OPTION,
    payload: { variantId },
  });
};

export const fixUnsyncedProduct =
  (productId, healthRecordId, fixType) => (dispatch) => {
    dispatch({
      type: FIX_UNSYNCED_PRODUCT_REQ,
      payload: { productId },
    });
    fetch(`${api}/unsynced/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        imported_product_id: productId,
        health_record_id: healthRecordId,
        fix_type: fixType,
      }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: FIX_UNSYNCED_PRODUCT_RES,
          payload: { productId, fixed: response.fixed, error: response.error },
        });
      })
      .catch((response) => {
        dispatch({
          type: FIX_UNSYNCED_PRODUCT_ERR,
          payload: { productId, error: response.error },
        });
      });
  };

export const fetchUnsyncedProducts = () => (dispatch) => {
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/api/my-store-health/`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: FETCH_UNSYNCED_PRODUCTS_RES,
        payload: response.results,
      });
    })
    .catch(() => {
      dispatch({
        type: GENERIC_ERR,
        payload: { error: "Error retrieving unsynced products" },
      });
    });
};

export const importToStore = (importedProductIds) => (dispatch) => {
  dispatch({
    type: IMPORT_PRODUCT_REQ,
    payload: { importedProductIds },
  });

  const subdomain = getUrlSubdomain();

  fetch(`${api}/import/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      imported_product_ids: [...importedProductIds],
      subdomain,
    }),
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      if (!response.error && !response.errors) {
        trackEvent("Product Imported");
        dispatch({
          type: UPDATE_IMPORTED_TO_SHOPIFY_COUNT,
        });
        dispatch({
          type: IMPORT_PRODUCT_RES,
          payload: { importedProductIds },
        });
      } else {
        dispatch({
          type: IMPORT_PRODUCT_ERR,
          payload: {
            error: response.error,
            errors: response.errors,
            importedProductIds,
          },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: IMPORT_PRODUCT_ERR,
        payload: { error, importedProductIds },
      });
    });
};

export const addToCart = (productId, variantId) => (dispatch) => {
  dispatch({
    type: variantId ? ADD_VARIANT_TO_CART_REQ : ADD_PRODUCT_TO_CART_REQ,
    payload: { productId, variantId },
  });
  fetch(`${api}/cart_item/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      product: productId,
      variant_relation: variantId,
      quantity: 1,
    }),
  })
    .then((response) => checkStatus(response))
    .then(() => {
      dispatch({
        type: variantId ? ADD_VARIANT_TO_CART_RES : ADD_PRODUCT_TO_CART_RES,
        payload: { productId, variantId },
      });
    })
    .catch(() => {
      dispatch({
        type: variantId ? ADD_VARIANT_TO_CART_ERR : ADD_PRODUCT_TO_CART_ERR,
        payload: { productId, variantId },
      });
    });
};

export const fetchSupplier = (queryString) => (dispatch, getState) => {
  const storeId = get(getState(), ["base", "settings", "store", "id"]);

  dispatch({
    type: FETCH_SUPPLIER_REQ,
  });
  fetch(`${api}/supplier/${queryString}&store=${storeId}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((rawResponse) => checkStatus(rawResponse))
    .then((responseData) => {
      trackEvent("View Supplier");
      dispatch({
        type: FETCH_SUPPLIER_RES,
        payload: {
          supplier: responseData.supplier,
          pagination: responseData.pagination,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SUPPLIER_ERR,
        payload: { error: "Error retrieving supplier" },
      });
    });
};

export const setLastPaginationState = () => (dispatch) => {
  dispatch({
    type: SET_LAST_PAGINATION_STATE,
  });
};

export const setImportListItemSaved = (item_id) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_LIST_ITEM_SAVED_RES,
    payload: { item_id },
  });
};

export const showAllChanges = (notificationId) => (dispatch) => {
  dispatch({
    type: "products/SHOW_ALL_CHANGES",
    payload: { notificationId },
  });
};

export const fetchNotifications = (page, days, type) => (dispatch) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 30000); // 30 seconds
  dispatch({
    type: GENERIC_REQ,
  });
  fetch(`${api}/notifications/?page=${page}&days=${days}&type=${type}`, {
    signal: controller.signal,
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((rawResponse) => checkStatus(rawResponse))
    .then((response) => {
      dispatch({
        type: FETCH_NOTIFICATIONS_RES,
        payload: {
          notifications: response.notifications,
          pagination: response.pagination,
        },
      });
    })
    .catch((error) => {
      // Check if the error is an abortion
      if (error.name === "AbortError") {
        dispatch({
          type: GENERIC_ERR,
          payload: {
            error:
              "The request was aborted due to a timeout. Please refresh the page to try again.",
          },
        });
      } else {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "There was an error retrieving products" },
        });
      }
    })
    .finally(() => {
      clearTimeout(timeoutId);
    });
};

export const fetchSuppliers = (queryString) => (dispatch, getState) => {
  const storeId = get(getState(), ["base", "settings", "store", "id"]);

  dispatch({
    type: FETCH_SUPPLIERS_REQ,
  });
  fetch(`${api}/suppliers/${queryString}&store=${storeId}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      trackEvent("View Suppliers");
      dispatch({
        type: FETCH_SUPPLIERS_RES,
        payload: {
          suppliers: response.suppliers,
          pagination: response.pagination,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_SUPPLIERS_ERR,
        payload: { error: "Error retrieving suppliers" },
      });
    });
};

export const requestApproval =
  (supplierId, cancel = false) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: REQUEST_APPROVAL_REQ,
        payload: { supplier_id: supplierId },
      });
      fetch(`${api}/request_approval/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          supplier_id: supplierId,
          cancel: cancel,
          date: moment(),
        }),
      })
        .then((rawResponse) => checkStatus(rawResponse))
        .then((responseData) => {
          trackEvent("Send Vendor Request");
          if (!responseData.error) {
            if (!cancel) successNotification("Requested successfully");
            else if (cancel)
              successNotification("Removed request successfully");
          }
          dispatch({
            type: REQUEST_APPROVAL_RES,
            payload: { supplier_id: supplierId, cancel: cancel },
          });
          resolve();
        })
        .catch((error) => {
          reject();
          dispatch({
            type: REQUEST_APPROVAL_ERR,
            payload: {
              error: "Error requesting approval",
              supplier_id: supplierId,
            },
          });
        });
    });

export const fetchImportedProducts =
  (page = 1, search = "", status = "all") =>
  (dispatch) => {
    dispatch({
      type: FETCH_IMPORTED_PRODUCTS_REQ,
    });
    fetch(
      `${api}/imported_products/?p=${page}&search=${search}&status=${status}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => checkStatus(response))
      .then((response) => {
        trackEvent("View Imported Products");
        dispatch({
          type: FETCH_IMPORTED_PRODUCTS_RES,
          payload: {
            issueCount: response.issue_count,
            warningCount: response.warning_count,
            importedProducts: response.products,
            pagination: response.pagination,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: FETCH_IMPORTED_PRODUCTS_ERR,
          payload: { error: "Error retrieving imported products" },
        });
      });
  };

export const fetchImportListProduct = (productId) => (dispatch, getState) => {
  const storeId = get(getState(), ["base", "settings", "store", "id"]);

  dispatch({
    type: FETCH_IMPORT_LIST_PRODUCT_REQ,
    payload: { product_id: productId },
  });
  fetch(`${api}/fetch_import_list_product/?id=${productId}&store=${storeId}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: FETCH_IMPORT_LIST_PRODUCT_RES,
        payload: {
          product_id: productId,
          product: response.product,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_IMPORT_LIST_PRODUCT_ERR,
        payload: { error: "Error retrieving product", product_id: productId },
      });
    });
};

export const getProductDescription = (productId) => () =>
  new Promise((resolve) => {
    fetch(`${api}/product_description/?product_id=${productId}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        resolve(response);
      })
      .catch(() => {
        resolve({ error: "Error fetching description" });
      });
  });

export const updateProductDescription = (productId) => (dispatch) => {
  dispatch({
    type: GENERATE_PRODUCT_DESCRIPTION_REQ,
    payload: { productId },
  });
  fetch(`${api}/product_description/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ product_id: productId }),
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      trackEvent("Generate Product Description");

      dispatch({
        type: GENERATE_PRODUCT_DESCRIPTION_RES,
        payload: { productId },
      });
    })
    .catch(() => {
      dispatch({
        type: GENERATE_PRODUCT_DESCRIPTION_ERR,
        payload: { productId },
      });
    });
};

export const fetchAllStoreProducts =
  (page = 1) =>
  (dispatch) => {
    dispatch({
      type: GENERIC_REQ,
    });
    fetch(`${api}/all_store_products/?p=${page}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        dispatch({
          type: FETCH_ALL_STORE_PRODUCTS_RES,
          payload: {
            products: response.products,
            // pagination: response.pagination,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: GENERIC_ERR,
          payload: { error: "Error retrieving products" },
        });
      });
  };

export const fetchImportList =
  (page = 1, search) =>
  (dispatch, getState) => {
    const storeId = get(getState(), ["base", "settings", "store", "id"]);

    dispatch({
      type: FETCH_IMPORT_LIST_REQ,
    });
    fetch(
      `${api}/fetch_import_list/?p=${page}&search=${search}&store=${storeId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${Cookies.get("accessToken")}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => checkStatus(response))
      .then((response) => {
        trackEvent("View Import List");

        dispatch({
          type: FETCH_IMPORT_LIST_RES,
          payload: {
            importList: response.import_list,
            pagination: response.pagination,
            productIds: response.product_ids,
            storeCategories: response.collections,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: FETCH_IMPORT_LIST_ERR,
          payload: { error: "Error retrieving products" },
        });
      });
  };

export const fetchProducts = (queryString) => (dispatch, getState) => {
  const storeId = get(getState(), ["base", "settings", "store", "id"]);

  dispatch({
    type: FETCH_PRODUCTS_REQ,
    payload: { request_id: queryString },
  });

  fetch(`${api}/products/${queryString}&store=${storeId ? storeId : null}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((rawResponse) => checkStatus(rawResponse))
    .then((data) => {
      trackEvent("View Products");

      dispatch({
        type: FETCH_PRODUCTS_RES,
        payload: {
          products: data.products,
          pagination: data.pagination,
          request_id: queryString,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_PRODUCTS_ERR,
        payload: {
          error: "Error retrieving products, please refresh the page",
        },
      });
    });
};

export const deleteSupplierProducts = (supplierId) => (dispatch) => {
  dispatch({
    type: DELETE_SUPPLIER_PRODUCTS_REQ,
    payload: { supplierId },
  });
  fetch(`${api}/delete_supplier_products/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ supplier_id: supplierId }),
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      trackEvent("Delete All Supplier Products");

      dispatch({
        type: DELETE_SUPPLIER_PRODUCTS_RES,
        payload: { supplierId },
      });
    })
    .catch(() => {
      dispatch({
        type: DELETE_SUPPLIER_PRODUCTS_ERR,
        payload: { supplierId },
      });
    });
};

export const markFeatured = (productId) => (dispatch) => {
  dispatch({
    type: MARK_FEATURED_REQ,
    payload: { productId },
  });
  fetch(`${api}/mark_featured/?product_id=${productId}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: MARK_FEATURED_RES,
        payload: { productId },
      });
    })
    .catch((error) => {
      dispatch({
        type: MARK_FEATURED_ERR,
        payload: { productId },
      });
    });
};

export const deleteProduct = (product, action) => (dispatch) => {
  dispatch({
    type: DELETE_PRODUCT_REQ,
    payload: { product },
  });
  fetch(`${api}/imported_products/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ product, action }),
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: DELETE_PRODUCT_RES,
        payload: { product },
      });
    })
    .catch((error) => {
      dispatch({
        type: DELETE_PRODUCT_ERR,
        payload: { product },
      });
    });
};

export const updateImportListItem = (item) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: UPDATE_IMPORT_LIST_ITEM_REQ,
      payload: { imported_product_id: item.imported_product_id },
    });
    fetch(`${api}/update_import_list_item/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ item }),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        if (!response.error) {
          trackEvent("Update Import List Item");
          resolve();
          dispatch({
            type: UPDATE_IMPORT_LIST_ITEM_RES,
            payload: { imported_product_id: item.imported_product_id },
          });
        } else {
          dispatch({
            type: UPDATE_IMPORT_LIST_ITEM_ERR,
            payload: {
              imported_product_id: item.imported_product_id,
              error: response.error,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_IMPORT_LIST_ITEM_ERR,
          payload: {
            imported_product_id: item.imported_product_id,
            error: error.error,
          },
        });
      });
  });

export const toggleSelectImportedProduct =
  (imported_product_id) => (dispatch) => {
    dispatch({
      type: TOGGLE_SELECT_IMPORT_LIST_ITEM_REQ,
      payload: { imported_product_id: imported_product_id },
    });
  };

export const toggleSelectImportedList = (selected) => (dispatch) => {
  dispatch({
    type: TOGGLE_SELECT_IMPORT_LIST_REQ,
    payload: { selected: selected },
  });
};

export const removeFromImportList = (importedProductId) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_IMPORT_LIST_REQ,
    payload: { imported_product_id: importedProductId },
  });

  fetch(`${api}/remove_from_import_list/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ imported_product_id: importedProductId }),
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      dispatch({
        type: REMOVE_FROM_IMPORT_LIST_RES,
        payload: {
          imported_product_id: importedProductId,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: REMOVE_FROM_IMPORT_LIST_ERR,
        payload: {
          imported_product_id: importedProductId,
        },
      });
    });
};

export const addToImportList = (productId) => (dispatch) => {
  dispatch({
    type: ADD_TO_IMPORT_LIST_REQ,
    payload: { product: productId },
  });

  fetch(`${api}/import_list/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ product_id: productId }),
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      if (!response.error) {
        trackEvent(`Product Added To Import List`);

        const localhost = window.location.host.includes("localhost");

        if (!localhost && response.import_list_count) {
          window.Intercom("boot", {
            app: window.location.href.includes("global")
              ? "GLOBAL"
              : "DROPCOMMERCE",
            account_type: "Store",
            app_id: "ulrlc7kx",
            email: response.email,
            import_list_count: response.import_list_count,
            store_id: response.store_id,
          });
        }

        dispatch({
          type: UPDATE_IMPORT_LIST_COUNT,
        });

        dispatch({
          type: ADD_TO_IMPORT_LIST_RES,
          payload: {
            product: productId,
          },
        });
      } else {
        dispatch({
          type: ADD_TO_IMPORT_LIST_ERR,
          payload: {
            error: response.error,
            product: productId,
          },
        });
        dispatch({
          type: "base/SET_ERR",
          payload: { error: response.error },
        });
      }
    });
};

export const importProduct = (product) => (dispatch) => {
  fetch(`${api}/shopify_import/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ product }),
  })
    .then((response) => checkStatus(response))
    .then((response) => {
      if (!response.error && !response.errors) {
        trackEvent("Product Imported");
        dispatch({
          type: IMPORT_PRODUCT_RES,
          payload: {
            product: product.product_id,
          },
        });
      } else {
        dispatch({
          type: IMPORT_PRODUCT_ERR,
          payload: { error: response.error, product: product.product_id },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: IMPORT_PRODUCT_ERR,
        payload: { error, product: product.product_id },
      });
      createNotification({
        type: "danger",
        title: "Error Importing Product",
        message: error.message,
      });
    });
};

export const createReview = (data) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: CREATE_REVIEW_REQ,
    });
    fetch(`${api}/reviews/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((rawResponse) => checkStatus(rawResponse))
      .then((data) => {
        if (data.error) {
          dispatch({
            type: CREATE_REVIEW_ERR,
            payload: { error: data.error },
          });
        } else {
          resolve();
          dispatch({
            type: CREATE_REVIEW_RES,
            payload: data.reviews,
          });
        }
      })
      .catch((error) => {
        errorNotification("Something went wrong");
        dispatch({
          type: CREATE_REVIEW_ERR,
          payload: { error: error.error },
        });
      });
  });

export const createRefundRequest = (data) => (dispatch) =>
  new Promise((resolve) => {
    fetch(`${api}/api/refunds/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => checkStatus(response))
      .then((response) => {
        if (response.error) {
          resolve(response);
          dispatch({
            type: CREATE_REFUND_ERR,
            payload: { error: response.error },
          });
        } else {
          resolve(response);
          dispatch({
            type: CREATE_REFUND_RES,
            payload: response.refunds,
          });
        }
      })
      .catch((response) => {
        dispatch({
          type: CREATE_REFUND_ERR,
          payload: { error: response.error },
        });
      });
  });
