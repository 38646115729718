import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../modules/Theme";
import Text from "../modules/Text";
import { confirmPlan, createPlan, fetchSettings } from "../Actions";
import {
  getIsFetching,
  getIsFetchingSettings,
  getSettings,
} from "../Selectors";
import { get, isEmpty } from "lodash";
import MDSpinner from "react-md-spinner";
import { getDefaultStoreRoute, getUrlParameter } from "../modules/Format";
import moment from "moment";
import { ButtonNew, Row, Switch } from "../components";
import StripeCard from "../components/StripeCard";
import { Modal } from "../components/Modal";
import { FiCheck } from "react-icons/fi";
import {
  AccountButton,
  AccountSubtitle,
  AccountTitle,
} from "../views/account/components/AccountComponents";
import { getRequest } from "../modules/API";

const ErinTestimonial = styled.img`
  margin-top: 30px;
  height: 350px;
  @media screen and (max-width: 800px) {
    height: auto;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const mapStateToProps = (state) => ({
  settings: getSettings(state),
  isFetching: getIsFetching(state),
  isFetchingSettings: getIsFetchingSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      createPlan,
      confirmPlan,
      fetchSettings,
    },
    dispatch
  ),
});

const Plan = ({
  hide,
  price, // The price to display
  description,
  paymentPlan, // The store's current PaymentPlan
  planName, // The plan name, in all caps
  planFrequency,
  features,
  selectPlan,
  cardSaved,
  platform,
  forceAccent,
}) => {
  // const planHierarchy = {
  //   BASIC: 1,
  //   GROWTH: 2,
  //   PROFESSIONAL: 3,
  //   EXECUTIVE: 4,
  // };

  const current =
    get(paymentPlan, "type") === planFrequency &&
    get(paymentPlan, "plan") === planName &&
    get(paymentPlan, "active");

  let accent = current || forceAccent;
  const annual = planFrequency === "ONE_TIME";

  // Handle Button text logic
  const wixButtonText = platform.name === "WIX" ? "UPGRADE" : null;
  let buttonText = annual ? "BUY NOW" : wixButtonText || "TRY IT FOR FREE";
  if (current) {
    buttonText = "CURRENT PLAN";
  } else if (paymentPlan) {
    buttonText = "CHOOSE PLAN";
  }

  // Parse current settings
  const platformName = get(platform, "name");
  // const currentPlan = get(paymentPlan, "plan");

  return (
    <PlanContainer accent={accent} hide={hide}>
      {/* Header */}
      <PlanCardTop>
        <PlanNameText>{planName}</PlanNameText>
        <PlanPriceRow>
          <PlanPrice>${price}</PlanPrice>
          <PlanPriceText>{annual ? "/yr" : "/mo"}</PlanPriceText>
        </PlanPriceRow>
        {description && (
          <Text extra="margin: 0 0 15px 0" green>
            {description}
          </Text>
        )}
      </PlanCardTop>
      {/* Description */}
      {features.map((feature, i) => (
        <Row
          align
          style={{ marginBottom: "5px", marginTop: "5px", marginLeft: "10px" }}
          key={i}
        >
          <FiCheck size="20" color={theme.colors.peach} />
          <PlanFeature>{feature}</PlanFeature>
        </Row>
      ))}
      {/* Button */}
      {platformName === "BIGCOMMERCE" && !cardSaved ? (
        <StripeCard
          margin="15px 0 0 0"
          text="Add Payment Card"
          description="Authorize Your Credit Card"
          panelLabel="Authorize Card"
          redirectUrl="/settings/plans"
        />
      ) : (
        <PlanButton
          accent={accent}
          onClick={() =>
            current && get(paymentPlan, "type") === planFrequency
              ? null
              : selectPlan(planName)
          }
        >
          {buttonText}
        </PlanButton>
      )}
    </PlanContainer>
  );
};

class PricingOptions extends Component {
  constructor(props) {
    super(props);
    // Initialize state based on props
    this.state = {
      planFrequency:
        get(props, ["settings", "store", "payment_plan", "type"]) ||
        "RECURRING",
      downgradeWarning: null,
      downgradePlan: null,
      showPastOffers: false,
      pastOffers: [],
      nextCharge: "",
    };
  }

  pastOffersModal = () =>
    this.state.showPastOffers === false
      ? this.setState({ showPastOffers: true })
      : this.setState({ showPastOffers: false });

  selectPlan = (newPlan) => {
    const currentPlan = get(this.props, ["settings", "store", "payment_plan"]);
    const currentPlanName = currentPlan ? currentPlan.plan : "";
    const currentPlanIsActive = currentPlan ? currentPlan.active : false;

    if (
      newPlan === "BASIC" &&
      ["PROFESSIONAL", "GROWTH"].includes(currentPlanName) &&
      currentPlanIsActive
    ) {
      this.setState({
        downgradePlan: newPlan,
        downgradeWarning:
          "If you downgrade to the Basic plan, you will only have access to 25 products, and you will lose any you have imported beyond that number. Be very careful when downgrading to this plan.",
      });
    } else if (
      newPlan === "GROWTH" &&
      currentPlan === "PROFESSIONAL" &&
      currentPlanIsActive
    ) {
      this.setState({
        downgradePlan: newPlan,
        downgradeWarning:
          "If you downgrade to the Growth plan, you will be limited to 250 products, and you will lose any you have imported beyond that number. Be very careful when downgrading to this plan.",
      });
    } else {
      this.choosePlan(newPlan, this.state.planFrequency, "");
    }
  };

  getPrice = (selectedFrequency, annualPrice, monthlyPrice) => {
    const settings = get(this.props, ["settings"]);
    const saleActive = get(settings, ["global", "show_sale_banner"]);
    const price = selectedFrequency === "ONE_TIME" ? annualPrice : monthlyPrice;
    if (!saleActive) {
      return price;
    }
    // Sale Logic
    let salePrice = 0;
    const paymentPlan = get(settings, ["store", "payment_plan"]);
    if (!paymentPlan) {
      salePrice = price * 0.75;
    } else if (
      paymentPlan.type !== "ONE_TIME" &&
      selectedFrequency === "ONE_TIME"
    ) {
      salePrice = price * 0.75;
    } else {
      salePrice = price;
    }
    return salePrice;
  };

  choosePlan = (plan, frequency, couponCode) => {
    this.setState({ downgradePlan: null, downgradeWarning: null });
    const settings = get(this.props, ["settings"]);
    const storeId = get(settings, ["store", "id"]);
    this.props.createPlan(plan, frequency, couponCode, storeId);
  };

  calculateBillingDate = (storeId) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (!storeId || storeId === "undefined") return;

    const paymentPlan = this.props.settings.store.payment_plan;

    // Handle empty response
    if (isEmpty(paymentPlan)) {
      this.setState({ nextCharge: "No payment plan active." });
      return;
    }

    let nextBillingDate = new Date(paymentPlan.start_date).getDate();
    if (nextBillingDate > new Date().getDate()) {
      this.setState({
        nextCharge:
          "Next billing date is " +
          nextBillingDate +
          " " +
          monthNames[new Date().getMonth()] +
          ".",
      });
    } else {
      if (nextBillingDate <= new Date().getDate()) {
        this.setState({
          nextCharge:
            "Next billing date is " +
            nextBillingDate +
            " " +
            monthNames[new Date().getMonth() + 1] +
            ".",
        });
      }
    }
  }; // The above function just decides whether to display this month or next month, based on whether the user's billing date has already passed for the ongoing month.

  renderHeader = () => {
    const store = get(this.props, ["settings", "store"]);
    // const platformName = get(this.props, [
    //   "settings",
    //   "store",
    //   "platform",
    //   "name",
    // ]);
    const paymentPlan = get(store, "payment_plan");
    const tab =
      this.props.tab || getUrlParameter("tab", this.props.location) || "plans";

    if (tab === "promotions") {
      return (
        <React.Fragment>
          <Title modal={this.props.hideModal}>Make 2021 Your Year!</Title>
          <Text center light extra="margin-top: 15px; max-width: 600px;">
            Get started with the promotions below - here for a limited time!
          </Text>
        </React.Fragment>
      );
    }

    // PUBLIC PAGE
    if (!paymentPlan || !paymentPlan.active) {
      const planMessage =
        store.id > 141387
          ? "To import products, please upgrade to a paid plan"
          : "Your free Starter plan includes 25 product imports. To process orders, upgrade to a paid plan.";

      return (
        <React.Fragment>
          <Title>
            Current Plan: <HighlightedText>Starter (Free)</HighlightedText>
          </Title>
          <Text center light extra="margin-top: 15px; max-width: 600px;">
            {planMessage}
          </Text>
        </React.Fragment>
      );
    }

    if (this.props.planLimit) {
      return (
        <React.Fragment>
          <Title modal={this.props.hideModal}>
            You've reached your plan limit!
          </Title>
          <Text center light extra="margin-top: 15px; max-width: 600px;">
            {this.props.planLimit}
          </Text>
        </React.Fragment>
      );
    }

    // IN APP
    if (paymentPlan && paymentPlan.active) {
      return (
        <React.Fragment>
          <Title modal={this.props.hideModal}>
            Current Plan:
            <HighlightedText>
              {paymentPlan ? paymentPlan.plan : "Loading..."}
            </HighlightedText>
          </Title>

          {paymentPlan.expiry_date && (
            <div>
              Plan Expires:{" "}
              {moment(paymentPlan.expiry_date).format("MMM Do YYYY")}
            </div>
          )}
          {paymentPlan.type !== "LIFE_TIME" && (
            <>
              {paymentPlan.remaining_trial_days > 0 && (
                <div>
                  Trial Days Remaining: {paymentPlan.remaining_trial_days}
                </div>
              )}
            </>
          )}
          {paymentPlan.type === "RECURRING" && this.state.nextCharge !== "" && (
            <div>{this.state.nextCharge}</div>
          )}
        </React.Fragment>
      );
    }
  };

  componentDidMount() {
    window.tap("detect");
    getRequest({ url: "offers/" }).then((response) =>
      this.setState({ pastOffers: response.data })
    );

    const storeId = get(this.props, ["settings", "store", "id"]);
    this.calculateBillingDate(storeId);
    // const settings = get(this.props, ["settings"]);

    // Confirm the Payment Plan if it came back with parameters
    // For Shopify format
    const querystring = get(this.props, ["location", "search"]);
    let planId = null;
    if (querystring) {
      const params = new URLSearchParams(querystring);
      planId = params.get("charge_id");
    }
    const planType = get(this.props, ["match", "params", "type"]);

    if (planId && planType) {
      this.props.confirmPlan(planId, planType).then((response) => {
        if (response.payment_plan.is_first_time) {
          this.props.history.push(getDefaultStoreRoute);
        }
      });
    }
  }

  render() {
    const store = get(this.props, ["settings", "store"]);
    const paymentPlan = get(store, ["payment_plan"]);
    const supplierBasedImportLimit = get(store, "supplier_based_import_limit");
    const platform = get(store, "platform");
    const cardSaved = get(this.props, [
      "settings",
      "store",
      "stripe_connected",
    ]);

    if (this.props.isFetching || this.props.isFetchingSettings) {
      return (
        <Container modal={this.props.modal}>
          <MDSpinner singleColor="rgb(73,190,175)" size={50} />
        </Container>
      );
    }

    if (!platform && !this.props.modal) {
      return (
        <Container
          style={{ width: "800px", margin: "0 auto" }}
          modal={this.props.modal}
        >
          <AccountTitle>Connect A Platform To Continue</AccountTitle>
          <AccountSubtitle>
            Connect your existing online store to import products
          </AccountSubtitle>
          <AccountButton
            text="View Platform Settings"
            onClick={() => this.props.history.push("/settings/platforms")}
          />
        </Container>
      );
    }

    if (this.state.downgradeWarning) {
      return (
        <Container style={{ width: "800px", margin: "0 auto" }}>
          <Title modal={this.props.hideModal}>Are you sure?</Title>
          <Text center light extra="margin-top: 15px; margin-bottom: 30px;">
            {this.state.downgradeWarning}
          </Text>
          <DowngradeButtonsRow>
            <ButtonNew
              text="Cancel"
              type="main"
              extra="width: 200px; padding: 15px; margin-right: 15px;"
              fontStyle="font-size: 18px;"
              onClick={() =>
                this.setState({ downgradePlan: null, downgradeWarning: null })
              }
            />
            <ButtonNew
              text="Confirm Downgrade"
              type="secondary"
              extra="width: 200px; padding: 15px;"
              fontStyle="font-size: 18px;"
              onClick={() =>
                this.choosePlan(
                  this.state.downgradePlan,
                  this.state.planFrequency,
                  ""
                )
              }
            />
          </DowngradeButtonsRow>
        </Container>
      );
    }

    const tab =
      this.props.tab || getUrlParameter("tab", this.props.location) || "plans";

    // const tabs = [
    //   {
    //     name: "Plans",
    //     link: () => this.props.history.push("/settings/plans?tab=plans"),
    //     active: tab === "plans",
    //   },
    //   {
    //     name: "Promotions",
    //     link: () => this.props.history.push("/settings/plans?tab=promotions"),
    //     active: tab === "promotions",
    //   },
    // ];

    let basicFeatures = [
      "Import 25 Products",
      "Process Orders",
      "Product Samples",
    ];
    let growthFeatures = [
      "250 Products",
      "Branded Invoicing",
      "All Basic Features",
    ];
    let proFeatures = [
      supplierBasedImportLimit ? "Unlimited Suppliers" : "Unlimited Products",
      "Automated Fulfillment",
      "All Growth Features",
    ];
    let exeFeatures = [
      "Premium customer support",
      "Dedicated account manager",
      "All Pro Features",
    ];

    const freeShipping = get(this.props, [
      "settings",
      "store",
      "free_shipping",
    ]);

    if (freeShipping) {
      growthFeatures.push("Free Shipping First 10 US Orders");
      proFeatures.push("Free Shipping First 10 US Orders");
    }

    // const isNotWix = get(platform, ["name"]) !== "WIX";

    return (
      <div>
        <Container modal={this.props.modal}>
          {this.renderHeader()}

          {tab === "plans" && (
            <Row
              align
              justify
              style={{ marginTop: "30px", justifyContent: "space-between" }}
            >
              {get(paymentPlan, "type") !== "LIFE_TIME" ? (
                <>
                  <FrequencySwitchText>MONTHLY</FrequencySwitchText>
                  <Switch
                    noTopMargin
                    active={this.state.planFrequency === "ONE_TIME"}
                    toggle={() =>
                      this.setState((p) => ({
                        planFrequency:
                          p.planFrequency === "ONE_TIME"
                            ? "RECURRING"
                            : "ONE_TIME",
                      }))
                    }
                  />
                  <FrequencySwitchText style={{ marginLeft: "15px" }}>
                    ANNUAL
                  </FrequencySwitchText>
                </>
              ) : (
                <FrequencySwitchText style={{ marginLeft: "15px" }}>
                  LIFETIME PLAN
                </FrequencySwitchText>
              )}
            </Row>
          )}

          {this.state.showPastOffers && (
            <Modal hide={() => this.pastOffersModal()}>
              <OffersContainer>
                {this.state.pastOffers.length < 1 ? (
                  <Text light>
                    You have no past offers{" "}
                    <span role="img" aria-label="A sad face emoji">
                      ☹️
                    </span>
                  </Text>
                ) : (
                  this.state.pastOffers.map((offer, i) => {
                    return (
                      <Box key={i}>
                        <Text.Small style={{ marginBottom: "8px" }}>
                          {offer.details.name}
                        </Text.Small>
                        <Text.Small light>
                          {offer.details.description}
                        </Text.Small>
                      </Box>
                    );
                  })
                )}
              </OffersContainer>
            </Modal>
          )}

          {tab === "promotions" ? (
            <PlansRow>
              <Plan
                planFrequency="ONE_TIME"
                price={249.0}
                planName="PRO 6 MONTH"
                description="Save 53%"
                cardSaved={cardSaved}
                platform={platform}
                paymentPlan={paymentPlan}
                selectPlan={() =>
                  this.props.choosePlan(
                    "CUSTOM",
                    "new-years-pro-6-month-249.00"
                  )
                }
                forceAccent
                features={[
                  "6 Months Pro Plan",
                  "Unlimited Products",
                  "Automated Fulfillment",
                  "All App Features",
                ]}
              />
              <Plan
                planFrequency="ONE_TIME"
                price={399}
                planName="PRO ANNUAL"
                description="Save 63%"
                cardSaved={cardSaved}
                platform={platform}
                paymentPlan={paymentPlan}
                selectPlan={() =>
                  this.props.choosePlan("CUSTOM", "new-years-pro-annual-399")
                }
                forceAccent
                features={[
                  "12 Months Pro Plan",
                  "Unlimited Products",
                  "Automated Fulfillment",
                  "All App Features",
                ]}
              />
            </PlansRow>
          ) : (
            <PlansRow>
              <Plan
                hide={this.state.planFrequency === "ONE_TIME"}
                planFrequency={this.state.planFrequency}
                price={this.state.planFrequency === "ONE_TIME" ? 228 : 19}
                planName="BASIC"
                cardSaved={cardSaved}
                platform={platform}
                paymentPlan={paymentPlan}
                selectPlan={this.selectPlan}
                features={basicFeatures}
              />
              <Plan
                planFrequency={this.state.planFrequency}
                price={this.getPrice(this.state.planFrequency, 349, 49)}
                planName="GROWTH"
                cardSaved={cardSaved}
                platform={platform}
                paymentPlan={paymentPlan}
                selectPlan={this.selectPlan}
                features={growthFeatures}
              />
              <Plan
                planFrequency={this.state.planFrequency}
                price={this.getPrice(this.state.planFrequency, 499, 89)}
                planName="PROFESSIONAL"
                cardSaved={cardSaved}
                platform={platform}
                paymentPlan={paymentPlan}
                selectPlan={this.selectPlan}
                features={proFeatures}
              />
              <Plan
                planFrequency={this.state.planFrequency}
                price={this.getPrice(this.state.planFrequency, 999, 149)}
                planName="EXECUTIVE"
                cardSaved={cardSaved}
                platform={platform}
                paymentPlan={paymentPlan}
                selectPlan={this.selectPlan}
                features={exeFeatures}
              />
            </PlansRow>
          )}

          {tab === "plans" && !paymentPlan && (
            <Text.Small center light extra="margin-bottom: 20px;">
              Free trials come with a satisfaction guarantee. Simply cancel
              during the trial and you will not be billed.
            </Text.Small>
          )}

          <ErinTestimonial src="https://dc-imports.s3.ca-central-1.amazonaws.com/erintestimonial-2.png" />

          {get(paymentPlan, "plan") !== "PROFESSIONAL" && (
            <JustBrowsingButton
              onClick={
                this.props.hideModal
                  ? this.props.hideModal
                  : () => this.props.history.push(getDefaultStoreRoute)
              }
            >
              I'm just browsing. Upgrade later.
            </JustBrowsingButton>
          )}
        </Container>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PricingOptions)
);

const DowngradeButtonsRow = styled.div`
  display: flex;
`;

const JustBrowsingButton = styled.span`
  color: ${theme.colors.main};
  font-weight: 400;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  padding: 15px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 25px;
  &:hover {
    color: ${theme.colors.mainDark};
    background: ${theme.colors.ultraLightGrey};
  }
`;

const HighlightedText = styled.span`
  color: ${theme.colors.main};
  font-weight: 400;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding-top: ${(p) => (p.modal ? "0px" : "30px")};
  ${(p) => p.modal && "min-width: 400px; min-height: 400px;"}
  @media (max-width: 800px) {
    text-align: center;
    ${(p) => p.modal && "width: 100%; min-height: 400px;"}
  }
`;

const FrequencySwitchText = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  color: ${theme.colors.medDarkGrey};
`;

const PlanPriceRow = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const PlanCardTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PlanNameText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${theme.colors.peach};
  letter-spacing: 3px;
`;

const PlanPrice = styled.div`
  font-size: 40px;
  font-weight: ${(p) => (p.strike ? 300 : 600)};
  color: ${theme.colors.darkGreen};
`;

const PlanPriceText = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: ${(p) => (p.color ? p.color : theme.colors.medGrey)};
  margin-bottom: 5px;
`;

const Title = styled.div`
  font-size: ${(p) => (p.small ? "32px" : "40px")};
  font-weight: 600;
  margin-top: ${(p) => (p.modal ? "30px" : "30px")};
  text-align: center;
  @media (max-width: 800px) {
    font-size: ${(p) => (p.small ? "24px" : "32px")};
    margin-top: 20px;
  }
`;

const PlanFeature = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
`;

const PlansRow = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 850px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const PlanButton = styled.div`
  letter-spacing: 1px;
  color: ${theme.colors.main};
  font-weight: 500;
  color: ${(p) => (p.accent ? "white" : theme.colors.mainDark)};
  background: ${(p) =>
    p.accent ? theme.colors.main : "rgba(73, 190, 175, 0.2)"};
  width: 250px;
  padding: 15px;
  margin: 0 auto;
  margin-top: 15px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  @media (max-width: 1200px) {
    width: 100%;
  }
  &:hover {
    background: ${(p) =>
      p.accent ? theme.colors.mainDark : "rgba(73, 190, 175, 0.3)"};
  }
`;

const OffersContainer = styled.div`
  border: 4px solid white;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0.5px solid #dae7e5;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  flex-basis: 45%;
  margin-bottom: 20px;
  box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.15);
  @media (max-width: 800px) {
    flex-basis: 80%;
  }
`;

const PlanContainer = styled.div`
  box-sizing: border-box;
  display: ${(p) => (p.hide ? "none" : "flex")};
  flex-direction: column;
  border-radius: 5px;
  margin: 15px;
  padding-top: 30px;
  border: 1px solid ${theme.colors.lightBorder};
  ${(p) =>
    p.accent && !p.annual && `border-top: 6px solid ${theme.colors.main}`};
  -webkit-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.15);
  @media (max-width: 850px) {
    width: 95%;
    margin: 10px;
    padding: 25px;
  }
`;
